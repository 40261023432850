<template>
  <div class="order-page-content">
    <Empty photo="3" label="暂无工单信息" v-if="total == 0"/>
    <div class="order-list" v-else>
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="loadData"
      >
        <div class="order-card" v-for="(item, index) in list" :key="index">
          <router-link :to="'/order/detail/' + item.id" class="order-link">
            <div class="order-card__hd">
              <div class="title">
                <div class="order-tag flex-center" :class="{'orange': item.type == 5, 'blue': item.type != 5}">{{ item.type_title }}</div>
                <div class="order-name van-ellipsis">{{ item.product_category }}</div>
              </div>
              <div class="status">已取消</div>
            </div>
            <div class="order-card__bd">
              <div class="photo">
                <img :src="item.product_photo" class="contain-img" alt="">
              </div>
              <div class="info">
                <!-- 送货 -->
                <template v-if="item.type == 1 || item.type == 8 || item.type == 6 || item.type == 7 || item.type == 9 || item.type == 10">
                  <div class="info-item">产品型号：{{ item.product_title }}</div>
                  <!-- <div class="info-item">服务时间：{{ item.appoint_time }}</div> -->
                  <div class="info-item">工单单号：{{ item.order_sn }}</div>
                </template>
                <!-- KDS，安装，保养，清洗，前置 -->
                <template v-else-if="item.type == 2 || item.type == 3 || item.type == 4">
                  <div class="info-item" v-if="item.logistics_status == 1">物流状态：已到货</div>
                  <div class="info-item" v-else-if="item.logistics_status == 2">物流状态：未到货</div>
                  <div class="info-item" v-else>物流状态：</div>
                  <div class="info-item">产品型号：{{ item.product_title || '型号暂未填写' }}</div>
                  <!-- <div class="info-item">服务时间：{{ item.appoint_time }}</div> -->
                  <div class="info-item">工单单号：{{ item.order_sn }}</div>
                </template>
                <!-- 维修 -->
                <template v-else-if="item.type == 5">
                  <div class="info-item">故障类型：{{ item.gz_title }}</div>
                  <div class="info-item">产品型号：{{ item.product_title || '型号暂未填写' }}</div>
                  <div class="info-item">工单单号：{{ item.order_sn }}</div>
                </template>
              </div>
            </div>
          </router-link>
          <div class="order-card__ft van-hairline--top">
            <div class="order-card__btn flex-center van-hairline--surround" @click.stop="onCancel(item.type)">
              <span>重新下单</span>
            </div>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
  import Empty from '@/components/empty'
  import { getOrderList } from '@/api/order'
  import { List } from 'vant'

  export default {
    components: {
      [List.name]: List,
      Empty
    },
    data() {
      return {
        list: [],
        loading: false,
        finished: false,
        total: -1,
        page: 1,
        pageSize: 10
      }
    },
    methods: {
      loadData() {
        const params = {
          page: this.page,
          limit: this.pageSize,
          status: 5
        }
        getOrderList(params).then(res => {
          this.list = this.list.concat(res.data.list)
          this.total = res.data.total
          this.loading = false
          this.page ++
          if (this.list.length >= this.total) {
            this.finished = true
          }
        })
      },
      /**
       * 重新下单
       */
      onCancel(type) {
        const router = [
          'sh',
          'kds',
          'qz',
          'az',
          'wx',
          'by',
          'qx',
          'gqy',
          'cg',
          'hx'
        ]
        this.$router.push('/service/' + router[type - 1])
      }
    },
  }
</script>

<style lang="less" scoped>
  @import './style.less';
</style>